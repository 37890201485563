<template>
  <div class="page flex-col">
    <div class="box_23 flex-col">
      <div class="box_24 flex-row">
        <span class="text_61">9:41</span>
        <img
          class="label_42"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngab1f668ef389f8d1eabe926edde5b79edcd3b25e5eef9ee6f587487a865052d1"
        />
        <img
          class="label_43"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga8787b58a7be32d2cf2201bba25050f496df02953514fa23ffe6e318b971df2a"
        />
        <img
          class="image_98"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd5f65e1b1646778e8abcd7b7f8550f29f733694823847b68f9e9aa344d7566ac"
        />
      </div>
      <div class="nav-bar_5 flex-row">
        <img
          class="icon_7"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/9d0b8d8273754480a27d367bb1cb3fe3_mergeImage.png"
        />
        <span class="text_62">IPmotion</span>
        <img
          class="image_99"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cf41290e1fd34a91a9d0cb04d998abd7_mergeImage.png"
        />
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="box_3 flex-col">
        <div class="group_29 flex-row">
          <div class="image-wrapper_43 flex-col justify-between">
            <img
              class="thumbnail_37"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge46e54ad93247d032bf1d42c9eeeb10dbbd3359e210041a072440cbf3d05d4c5"
            />
            <img
              class="label_44"
              referrerpolicy="no-referrer"
              src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/efbdbc1eca594efaaca322e002882572_mergeImage.png"
            />
          </div>
          <img
            class="image_100"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/921130441f9249deb46623fb81f79784_mergeImage.png"
          />
          <img
            class="image_101"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d7d3662299b2453080773ec3dd721bfd_mergeImage.png"
          />
          <img
            class="image_102"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d09a8c4e6d7243f49f952354fe528deb_mergeImage.png"
          />
          <img
            class="label_4"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2dc6ef30474ae45c772066844a621d16a48cd3c5ef2d0c5f71858e9eb26ffea5"
          />
        </div>
        <div class="group_30 flex-row">
          <div class="text-group_5 flex-col justify-between">
            <span class="text_3">Creativity</span>
            <span class="text_4">inspiration-driven</span>
          </div>
        </div>
        <div class="image-wrapper_44 flex-row">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng38defbd0dce3bab0cd50a501194fe278ec9181a73f576ac3d057bb954c0aefb6"
          />
        </div>
      </div>
    </div>
    <div class="box_25 flex-col">
      <div class="text-wrapper_105 flex-row">
        <span class="text_5">“</span>
        <span class="text_6"
          >整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销</span
        >
      </div>
      <span class="text_7"
        >IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。</span
      >
      <span class="text_8"
        >成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。</span
      >
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6969a4205cba0d3cafd64c87cd32dcfa1acc7bb869eb4daa2fd840392481e9b6"
      />
      <span class="text_9">合作客户</span>
      <div class="box_5 flex-col">
        <div class="image-wrapper_45 flex-row">
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc4975b19339f3bbbac580d1e1ce6401a910de15642ef871aab7145f6d6dca62d"
          />
          <img
            class="image_9"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5acbdf4b0a158f65f3d07c36bdd20a65d28bb323baa12b23c003d791fc61f5f6"
          />
          <img
            class="image_10"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7652464d85778f9df994e5b3b4123c666f1f1aea99c2290043c8ad65466fb8b2"
          />
        </div>
        <div class="image-wrapper_46 flex-row">
          <img
            class="image_11"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng95efa37ca72be0f88fbea5668322f6376f6df2b65b908e298dd55a86b4b10007"
          />
          <img
            class="image_12"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5da5b42b4b37ea258a0abd8ef2649383a922041a4503f312559f669fc5a59150"
          />
          <img
            class="image_13"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng09491b25925ee9e712bb60593fae2e4633b8dd57155cce308234e254b6db5a94"
          />
        </div>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="group_31 flex-row">
        <div class="image-wrapper_47 flex-col justify-between">
          <img
            class="thumbnail_38"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd212a337bc44087aa5b157e7c88bdc83f4cc7343de052e0b97eb4f0b72118b2f"
          />
          <img
            class="label_45"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d316238d51444b5b998ee98679553540_mergeImage.png"
          />
        </div>
        <img
          class="image_103"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/acc99da7b6a043bfad7a5f4ee0f8574d_mergeImage.png"
        />
        <img
          class="image_104"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5877553f10894e808fe070d6f47ffb5f_mergeImage.png"
        />
        <img
          class="image_105"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/beb832e3ff354e83bb522d7bb0b7b80a_mergeImage.png"
        />
        <img
          class="image_17"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng351eb22cf193ca87cc90d0e5bf999fbae1d84631e8af7222be64f78b65ea369d"
        />
      </div>
      <div class="text-wrapper_106 flex-row">
        <span class="text_10">展览展厅&amp;活动</span>
      </div>
      <div class="text-wrapper_107 flex-row">
        <span class="text_11">提供前沿的创意理念，及线下制作的一体化服务</span>
      </div>
      <div class="text-wrapper_108 flex-row justify-between">
        <span class="text_12">●</span> <span class="text_13">临时展厅</span>
      </div>
      <div class="text-wrapper_109 flex-row justify-between">
        <span class="text_14">●</span> <span class="text_15">常设展厅</span>
      </div>
      <div class="text-wrapper_110 flex-row justify-between">
        <span class="text_16">●</span> <span class="text_17">多媒体互动</span>
      </div>
      <div class="text-wrapper_111 flex-row justify-between">
        <span class="text_18">●</span> <span class="text_19">交互内容</span>
      </div>
      <div class="text-wrapper_112 flex-row justify-between">
        <span class="text_20">●</span> <span class="text_21">线下活动</span>
      </div>
      <img
        class="image_18"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfd5e385b7d8d8400ad7091615780cd52398e0cf037383010705f16a08e61a928"
      />
    </div>
    <div class="box_7 flex-col">
      <div class="box_26 flex-row">
        <div class="image-wrapper_48 flex-col justify-between">
          <img
            class="thumbnail_39"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd212a337bc44087aa5b157e7c88bdc83f4cc7343de052e0b97eb4f0b72118b2f"
          />
          <img
            class="label_46"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ebf1edb9eafd41a2847d5e83181bfe21_mergeImage.png"
          />
        </div>
        <img
          class="image_106"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/7438ef4cce0a42febad2ca614bffdafe_mergeImage.png"
        />
        <img
          class="image_107"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d22e514dfbfb4b59b2eb2d8e0008455b_mergeImage.png"
        />
        <img
          class="image_108"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3c43851c69ac4c7eb434e4f139a7674a_mergeImage.png"
        />
        <img
          class="image_22"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9a13edafa42be22ac174e76a28052561332ad6111a6a9166e5da942ba9896862"
        />
      </div>
      <div class="image-wrapper_49 flex-row">
        <img
          class="image_23"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd1c40c2110bbd869289098928db3fd118e8d01e6959006671b52ceac7a6ea213"
        />
      </div>
      <div class="text-wrapper_113 flex-row">
        <span class="text_22">覆盖线上、线下，整合全渠道的推广传播</span>
      </div>
      <div class="text-wrapper_114 flex-row justify-between">
        <span class="text_23">●</span> <span class="text_24">数字营销</span>
      </div>
      <div class="text-wrapper_115 flex-row justify-between">
        <span class="text_25">●</span> <span class="text_26">社会化传播</span>
      </div>
      <div class="text-wrapper_116 flex-row justify-between">
        <span class="text_27">●</span> <span class="text_28">TVC视频</span>
      </div>
      <div class="text-wrapper_117 flex-row justify-between">
        <span class="text_29">●</span> <span class="text_30">宣传片</span>
      </div>
      <div class="text-wrapper_118 flex-row">
        <span class="text_31">数字营销&amp;视频创作</span>
      </div>
    </div>
    <div class="box_8 flex-col">
      <div class="group_32 flex-row">
        <div class="image-wrapper_50 flex-col justify-between">
          <img
            class="thumbnail_40"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd212a337bc44087aa5b157e7c88bdc83f4cc7343de052e0b97eb4f0b72118b2f"
          />
          <img
            class="label_47"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d3844faa4a394f54ad2a9fa0d106529a_mergeImage.png"
          />
        </div>
        <div class="block_14 flex-col justify-between">
          <div class="image-wrapper_51 flex-row justify-between">
            <img
              class="image_109"
              referrerpolicy="no-referrer"
              src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8fbfd5423c1747db80888e6ee26ae573_mergeImage.png"
            />
            <img
              class="image_110"
              referrerpolicy="no-referrer"
              src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0cc29aed1fdb4654ac63bb6adf7bda00_mergeImage.png"
            />
            <img
              class="image_111"
              referrerpolicy="no-referrer"
              src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/2768afb2808540e3a371f05d89405319_mergeImage.png"
            />
          </div>
          <img
            class="image_112"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng28fcce9262dcc0cf2ca553e009cfdf962eccdfe7bfea3375ea4ac81a66808615"
          />
        </div>
        <img
          class="icon_2"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng351eb22cf193ca87cc90d0e5bf999fbae1d84631e8af7222be64f78b65ea369d"
        />
      </div>
      <div class="text-wrapper_119 flex-row">
        <span class="text_32">智能平台&amp;多媒体互动</span>
      </div>
      <div class="text-wrapper_120 flex-row">
        <span class="text_33">提供服务与解决方案的智能科技应用平台</span>
      </div>
      <div class="text-wrapper_121 flex-row justify-between">
        <span class="text_34">●</span> <span class="text_35">智能会务系统</span>
      </div>
      <div class="text-wrapper_122 flex-row justify-between">
        <span class="text_36">●</span> <span class="text_37">智能云展</span>
      </div>
      <div class="text-wrapper_123 flex-row justify-between">
        <span class="text_38">●</span> <span class="text_39">数字孪生</span>
      </div>
      <div class="text-wrapper_124 flex-row justify-between">
        <span class="text_40">●</span> <span class="text_41">智能机器人</span>
      </div>
      <img
        class="image_28"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5f4f1ad4d6757db97d6ac6cf569fed8f7a611bdadc7804e858b1db795b0fa7e1"
      />
    </div>
    <div class="box_9 flex-col">
      <div class="group_33 flex-row">
        <div class="image-wrapper_52 flex-col justify-between">
          <img
            class="thumbnail_41"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd212a337bc44087aa5b157e7c88bdc83f4cc7343de052e0b97eb4f0b72118b2f"
          />
          <img
            class="label_48"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5cf4b90c51c54e1fa16c8256d94a5bb2_mergeImage.png"
          />
        </div>
        <img
          class="image_113"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/b264e402bb324a9d88d4e8988341d21c_mergeImage.png"
        />
        <img
          class="image_114"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/06ccbf74c4a54f1ba1f0ce0e7b4bc9dd_mergeImage.png"
        />
        <img
          class="image_115"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d0a98e200bd24cf496b010af1a22754e_mergeImage.png"
        />
        <img
          class="icon_3"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9a13edafa42be22ac174e76a28052561332ad6111a6a9166e5da942ba9896862"
        />
      </div>
      <div class="text-wrapper_125 flex-row">
        <span class="text_42">奢侈品营销</span>
      </div>
      <div class="text-wrapper_126 flex-row">
        <span class="text_43">结合线下制作、运营于一体的互动体验</span>
      </div>
      <div class="text-wrapper_127 flex-row justify-between">
        <span class="text_44">●</span> <span class="text_45">促销活动</span>
      </div>
      <div class="text-wrapper_128 flex-row justify-between">
        <span class="text_46">●</span> <span class="text_47">体验营销</span>
      </div>
      <img
        class="image_32"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng55c4cb0b45fa9b1d3dd10aeef4001107dc5f3db00bf22b019fe218ea4f4066bd"
      />
    </div>
    <div class="box_27 flex-col">
      <div class="text-wrapper_129 flex-row">
        <span class="text_48">项目案例</span>
      </div>
      <div class="box_28 flex-row">
        <div class="group_34 flex-row justify-between">
          <div class="text-wrapper_26 flex-col">
            <span class="text_49">全部</span>
          </div>
          <div class="text-wrapper_27 flex-col">
            <span class="text_50">展览展厅&amp;活动</span>
          </div>
          <div class="text-wrapper_28 flex-col">
            <span class="text_51">数字营销&amp;视频创作</span>
          </div>
        </div>
        <div class="group_6 flex-col"></div>
        <span class="text_52">智能平台&amp;多媒体互动</span>
      </div>
    </div>
    <img
      class="image_33"
      referrerpolicy="no-referrer"
      src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8ca78239e7b9465d8e8afd8259999bc0_mergeImage.png"
    />
    <img
      class="image_34"
      referrerpolicy="no-referrer"
      src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/c4d652a59f0f418b823bc22b6633e9a4_mergeImage.png"
    />
    <img
      class="image_35"
      referrerpolicy="no-referrer"
      src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/b64264fb1b30470aad289859260d4fe4_mergeImage.png"
    />
    <img
      class="image_36"
      referrerpolicy="no-referrer"
      src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/2efd57cfdf614715a62f8c2cbf42d7de_mergeImage.png"
    />
    <div class="box_29 flex-col">
      <div class="text-wrapper_29 flex-col">
        <span class="text_53">更多案例</span>
      </div>
    </div>
    <div class="box_12 flex-col">
      <div class="box_30 flex-row">
        <img
          class="thumbnail_42"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc87cd8ffc929fb15f5b2ea34f4be6a9c9f2e0ab9021a72a7aa9f3e2d71347d9b"
        />
        <img
          class="thumbnail_43"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge6b93970567b5e01867e73ea761c4f4dfbe5690068b71969e4e628e29f00ca72"
        />
        <img
          class="image_116"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6a405ffce7fb798710ab2b426670fb9f8bdfc94a4fb897450eddb93273817434"
        />
        <div class="image-wrapper_53 flex-col">
          <img
            class="thumbnail_44"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd5e5dfc060f23968dfad97e06ec450a8b8913ead2a821c1dae802e2e550314a0"
          />
        </div>
        <img
          class="label_49"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4af0b88fa5aae3c646a956e2458f0269144dae7f58a627effd3fc26443b11fed"
        />
        <img
          class="label_50"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5270a774e53109567524650616b0661e48142af915d4671c9eeb14e298e2f1fd"
        />
        <img
          class="thumbnail_45"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge432a7d643e2fcbc0fddabc7f796ae2be6a19665acebcead6ec75ede88061c25"
        />
      </div>
      <span class="paragraph_1"
        >地址：上海市普陀区长寿路652号205室<br />电话：021-50583245&nbsp;&nbsp;&nbsp;&nbsp;<br />邮箱：hr&#64;ipmotionmc.com</span
      >
      <img
        class="image_38"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf8dd2c49ed57b20ac2a8932772236c18a464115f652402c90c25394be122334a"
      />
      <span class="text_54">IPmotion.&nbsp;沪ICP备2020037860号</span>
      <img
        class="home-indicator_3"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6a3371cd4d0283504ed1ad057ec2d9d039d32fe71d18487e72c83f38195f7151"
      />
      <img
        class="label_51"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng509b784bed6066035481b2372dfce54b04bfa07d30c1f8e775d321624975b43e"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style src="../assets/css/mcommon.css" />
<style src="../assets/css/mindex.css" />
